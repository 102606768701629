<template>
    <div class="app-container">
        <el-row id="queryForm">
            <el-form :inline="true" ref="queryForm" :model="queryForm" @submit.native.prevent>
                <el-form-item prop="querySelectedValue">
                    <el-input placeholder="请输入内容" v-model="queryForm.querySelectedValue" class="input-with-select">
                        <el-select clearable v-model="queryForm.querySelectedType" slot="prepend" placeholder="请选择">
                            <el-option label="姓名" value="userName"></el-option>
                            <el-option label="手机号" value="phone"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item prop="state">
                    <el-select clearable v-model="queryForm.state" placeholder="请选择账号状态">
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="禁用" value="2"></el-option>
                        <el-option label="过期" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="region">
                    <el-cascader filterable :props="{ checkStrictly: true }" placeholder="请选择区域" size="large" v-model="queryForm.region" :options="options" clearable></el-cascader>
                </el-form-item>
                <el-form-item prop="dataRange">
                    <el-date-picker clearable v-model="queryForm.dataRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                </el-form-item>
                <el-form-item class="floatRight">
                    <el-dropdown split-button type="primary" @click="queryData" @command="handleCommand">
                        查询
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="reset">重置查询</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="marginBottom-8" id="addBtnRow">
            <el-button type="primary" @click="addAccount">新增账号</el-button>
        </el-row>
        <el-table v-loading="listLoading" element-loading-text="加载中" :data="list" border fit highlight-current-row>
            <el-table-column label="用户编号" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="queryUserInfo(scope.row.userCode)">{{ scope.row.userCode }}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="姓名" align="center">
                <template slot-scope="scope">
                    {{ scope.row.userName }}
                </template>
            </el-table-column>
            <el-table-column label="手机号" align="center">
                <template slot-scope="scope">
                    {{ scope.row.phone }}
                </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
                <template slot-scope="scope">
                    <el-tag effect="dark" :type="state.AcountStateColorType[scope.row.state]">{{ state.AcountState[scope.row.state] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="所在区域" align="center">
                <template slot-scope="scope">
                    {{ scope.row.province + scope.row.city + scope.row.district }}
                </template>
            </el-table-column>
            <el-table-column label="注册时间" align="center">
                <template slot-scope="scope">
                    <span>{{ moment(scope.row.addTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-popconfirm title="确定删除吗？" @confirm="edit(scope.row.userCode, 1)">
                        <el-button slot="reference" type="text" size="small">删除</el-button>
                    </el-popconfirm>
                    <el-button class="marginLeft-8 marginRight-8" type="text" size="small" @click="editUser(scope.row.userCode)">修改</el-button>
                    <template v-if="scope.row.state != 3">
                        <el-popconfirm title="确定禁用吗？" @confirm="edit(scope.row.userCode, 2, scope.row.state)">
                            <el-button v-if="scope.row.state == 1" slot="reference" type="text" size="small">禁用</el-button>
                        </el-popconfirm>
                        <el-button v-if="scope.row.state == 2" @click="edit(scope.row.userCode, 2, scope.row.state)" type="text" size="small">启用</el-button>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="marginTop-8 floatRight" layout="total, sizes, prev, pager, next" background :current-page="queryForm.pageNum + 1" :page-size="queryForm.pageSize" :total="queryForm.totalCount" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        <add-acount :op="op" :addType="1" @onDialogClose="onDialogClose" :visible="diaLogShow" />
        <UserInfo :visible="drawerVisible" :userCode="userCode" @closeDrawer="closeDrawer" />
    </div>
</template>
<script>
import { getCollecAcountList, delUser, enableOrDisableUser } from "@/api/user";
import { regionData, CodeToText } from "element-china-area-data";
import state from "@/utils/state";
import moment from "moment";
import AddAcount from "../components/AddAcount";
import UserInfo from "@/components/UserInfo";

export default {
    components: {
        AddAcount,
        UserInfo,
    },
    data() {
        return {
            listLoading: true,
            list: [],
            userCode: null,
            drawerVisible: false,
            moment,
            diaLogShow: false,
            options: regionData,
            op: "add",
            queryForm: {
                querySelectedType: "userName",
                querySelectedValue: "",
                region: [],
                state: "",
                dataRange: "",
                pageNum: 0,
                pageSize: 10,
                totalCount: 0,
            },
        };
    },
    computed: {
        state() {
            return state.state;
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        closeDrawer() {
            this.drawerVisible = false;
            this.userCode = null;
        },
        queryUserInfo(userCode) {
            this.userCode = userCode;
            this.drawerVisible = true;
        },
        editUser(userCode) {
            this.op = userCode;
            this.addAccount();
        },
        onDialogClose(refeshFlag) {
            this.diaLogShow = false;
            this.op = "add";
            if (refeshFlag == true) {
                this.fetchData();
            }
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.fetchData();
        },
        handleCurrentChange(val) {
            this.queryForm.pageNum = val - 1;
            this.fetchData();
        },
        edit(userCode, type, userState) {
            // type: 1、删除操作，2、禁用操作
            let params = {
                userCode,
            };
            const _this = this;
            _this.listLoading = true;
            if (type == 1) {
                delUser(params)
                    .then(() => {
                        _this.$message({
                            message: "操作成功",
                            type: "success",
                        });
                        _this.fetchData();
                    })
                    .catch((err) => {
                        _this.listLoading = false;
                    });
            } else {
                if (userState == 1) {
                    params.state = 2;
                } else if (userState == 2) {
                    params.state = 1;
                }
                enableOrDisableUser(params)
                    .then(() => {
                        _this.$message({
                            message: "操作成功",
                            type: "success",
                        });
                        _this.fetchData();
                    })
                    .catch(() => {
                        _this.listLoading = false;
                    });
            }
        },
        addAccount() {
            this.diaLogShow = true;
        },
        handleCommand(type) {
            if (type == "reset") {
                this.$refs.queryForm.resetFields();
                this.fetchData();
            }
        },
        queryData() {
            this.fetchData();
        },
        fetchData() {
            this.listLoading = true;
            const queryForm = this.queryForm;
            let params = {
                pageNum: queryForm.pageNum,
                pageSize: queryForm.pageSize,
                userType: 1,
            };
            if (queryForm.querySelectedValue && queryForm.querySelectedValue != "") {
                params[queryForm.querySelectedType] = queryForm.querySelectedValue;
            }
            if (queryForm.state && queryForm.state != "") {
                params.state = queryForm.state;
            }
            if (queryForm.dataRange && queryForm.dataRange != "") {
                params.maxAddTime = moment(queryForm.dataRange[1]).format("YYYY-MM-DD") + " 23:59:59";
                params.minAddTime = moment(queryForm.dataRange[0]).format("YYYY-MM-DD") + " 00:00:00";
            }
            if (queryForm.region.length == 1) {
                params.province = CodeToText[queryForm.region[0]];
            } else if (queryForm.region.length == 2) {
                params.province = CodeToText[queryForm.region[0]];
                params.city = CodeToText[queryForm.region[1]];
            } else if (queryForm.region.length == 3) {
                params.province = CodeToText[queryForm.region[0]];
                params.city = CodeToText[queryForm.region[1]];
                params.district = CodeToText[queryForm.region[2]];
            }
            const _this = this;
            getCollecAcountList(params)
                .then((response) => {
                    _this.list = response.data.dataList;
                    _this.queryForm.totalCount = response.data.totalCount;
                    _this.listLoading = false;
                })
                .catch((err) => {
                    _this.listLoading = false;
                });
        },
    },
};
</script>
